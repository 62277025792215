const FloDesk = `<link rel="preload" href="https://assets.flodesk.com/futura-pt.css" as="style">
<link rel="stylesheet" href="https://assets.flodesk.com/futura-pt.css">
<style>
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 *,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 *::before,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 *::after {
    box-sizing: border-box;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [tabindex="-1"]:focus {
    outline: none !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h1,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h2,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h3,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h4,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h5,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 h6 {
    margin-top: 0;
    margin-bottom: 0.7em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ol,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ul,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 dl {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ol ol,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ul ul,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ol ul,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ul ol {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 b,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 strong {
    font-weight: bolder;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 small {
    font-size: 80%;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 sub,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 sub {
    bottom: -0.25em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 sup {
    top: -0.5em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 a:hover {
    color: #4396fd;
    text-decoration: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 img {
    border-style: none;
    vertical-align: middle;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 svg:not(:root) {
    overflow: hidden;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 area,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [role="button"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 label,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 select,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 summary,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 textarea {
    touch-action: manipulation;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 label {
    display: inline-block;
    font-weight: bolder;
    margin-bottom: 0.7rem;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button:focus {
    outline: 1px dotted;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 select,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 optgroup,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input {
    overflow: visible;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 select {
    text-transform: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button,
  html[data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="button"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="reset"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="submit"] {
    -webkit-appearance: button;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 button::-moz-focus-inner,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="button"]::-moz-focus-inner,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="reset"]::-moz-focus-inner,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="radio"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="checkbox"] {
    padding: 0;
    box-sizing: border-box;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="date"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="time"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="datetime-local"],
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 input[type="month"] {
    -webkit-appearance: listbox;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 textarea {
    resize: vertical;
    overflow: auto;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="number"]::-webkit-inner-spin-button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="search"]::-webkit-search-cancel-button,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 [hidden] {
    display: none !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control {
    width: 100%;
    display: block;
    outline: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control::-moz-placeholder {
    color: transparent !important;
    opacity: 0 !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control::placeholder {
    color: transparent !important;
    opacity: 0 !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-label {
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control:not(:-moz-placeholder-shown)+.fd-form-label {
    opacity: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-control:not(:placeholder-shown)+.fd-form-label {
    opacity: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-description {
    margin: 5px 0 0 0;
    font-size: 0.8em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-feedback {
    margin: 5px 0 0 0;
    font-size: 0.8em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group {
    margin: 0 0 15px;
    position: relative;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-success .fd-form-feedback,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-success .fd-form-check {
    color: #02dba8 !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-success .fd-form-control {
    color: #02dba8 !important;
    border-color: #02dba8 !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-success .fd-form-feedback {
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-error .fd-form-feedback,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-error .fd-form-check {
    color: #eb3d3b !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-error .fd-form-control {
    color: #eb3d3b !important;
    border-color: #eb3d3b !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-group.fd-has-error .fd-form-feedback {
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-btn {
    cursor: pointer;
    display: inline-flex;
    outline: none;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-style: normal;
    text-align: center;
    align-items: center;
    text-shadow: none;
    white-space: normal;
    justify-content: center;
    text-decoration: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-btn:hover {
    outline: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-btn:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-btn:disabled {
    opacity: 0.8;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check {
    cursor: pointer;
    margin: 0;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 30px;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__input {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__checkmark {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    background-size: 18px;
    background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z' fill='white'/%3e %3cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H16C18.0711 0.25 19.75 1.92893 19.75 4V16C19.75 18.0711 18.0711 19.75 16 19.75H4C1.92893 19.75 0.25 18.0711 0.25 16V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V16C1.75 17.2426 2.75736 18.25 4 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V4C18.25 2.75736 17.2426 1.75 16 1.75H4Z'/%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__label {
    margin: 0;
    font-size: 14px;
    text-align: left;
    word-break: break-word;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__input:checked+.fd-form-check__checkmark::after {
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__checkmark::after {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    content: "";
    display: block;
    opacity: 0;
    z-index: 1;
    position: absolute;
    transition: opacity 0.4s, z-index 0.4s;
    visibility: inherit;
    background-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='12' height='10' viewBox='0 0 11.51 8.2'%3e%3ctitle%3echeck%3c/title%3e%3cpath d='M4.05%2c8.2A.74.74%2c0%2c0%2c1%2c3.52%2c8L.22%2c4.68A.75.75%2c0%2c0%2c1%2c1.28%2c3.62l3.3%2c3.3A.75.75%2c0%2c0%2c1%2c4.58%2c8%2c.74.74%2c0%2c0%2c1%2c4.05%2c8.2Z'/%3e%3cpath d='M4.06%2c8.2A.74.74%2c0%2c0%2c1%2c3.53%2c8a.75.75%2c0%2c0%2c1%2c0-1.06l6.7-6.7a.75.75%2c0%2c0%2c1%2c1.06%2c1.06L4.59%2c8A.74.74%2c0%2c0%2c1%2c4.06%2c8.2Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__input:focus {
    outline: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-content {
    position: relative;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-has-success .fd-form-content {
    display: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-has-captcha .fd-form-content>*:not(.fd-form-captcha) {
    opacity: 0;
    visibility: hidden;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-captcha {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-success {
    width: 100%;
    display: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-has-success .fd-form-success {
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-success>*:last-child {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-error {
    display: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-has-error .fd-form-error {
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-error>*:last-child {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-focus-visible,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 calc(2px + 4px) #000000 !important;
    transition: box-shadow 0.2s !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-focus-visible,
  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 calc(2px + 2px) #717171 !important;
    transition: box-shadow 0.2s !important;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__container {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    max-width: 792px;
    background: #ffffff;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__wrapper {
    display: flex;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form {
    color: #F04C2C;
    width: 100%;
    margin: 0;
    padding: 70px;
    font-size: 16px;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.1px;
    text-transform: none;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form {
      padding: 25px;
      word-wrap: anywhere;
      word-break: break-word;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__title {
    color: #F04C2C;
    width: 100%;
    margin: 0 0 25px 0;
    display: block;
    font-size: 38px;
    text-align: center;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__title * {
    line-height: inherit;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__subtitle {
    width: 100%;
    margin: 0 0 30px 0;
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__subtitle * {
    line-height: inherit;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901[data-ff-stage="success"] .ff-6609986c71c34c123dd98901__content {
    display: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__fields {
    margin: 0 0 15px;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__fields>*:last-child {
    margin-bottom: 0;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__field {
    font-size: 18px;
    text-align: left;
    font-family: Garamond, serif;
    font-weight: 400;
    letter-spacing: 0.1px;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__control {
    color: #000000;
    border: none;
    height: 42px;
    padding: 10px 20px;
    font-size: 18px;
    background: transparent;
    text-align: left;
    font-family: Garamond, serif;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #000000;
    border-radius: 0px;
    letter-spacing: 0.1px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__label {
    color: #000000;
    border: 1px solid transparent;
    padding: 10px 20px;
    font-size: 18px;
    text-align: left;
    font-family: Garamond, serif;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference {
      flex-direction: column;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-title {
    color: #F04C2C;
    width: 100%;
    margin: 0 0 30px 0;
    display: block;
    font-size: 18px;
    text-align: left;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    line-height: 1.4000000000000001;
    letter-spacing: 0px;
    text-transform: none;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-title {
      margin: 0 0 30px;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-title * {
    line-height: inherit;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-control {
    width: 100%;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-list {
    width: calc(100% + 24px);
    margin: -7px -12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-list {
      flex-direction: column;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-item {
    flex: 0 0 50%;
    padding: 7px 12px;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__preference-item {
      flex: 1 1;
      max-width: 100%;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form-check .fd-form-check__input {
    top: -1px;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form-check .fd-form-check__checkmark {
    top: -1px;
    border-radius: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill='%23F04C2C' fill-rule='evenodd' clip-rule='evenodd' d='M1.2 9C1.2 13.3078 4.69218 16.8 9 16.8C13.3078 16.8 16.8 13.3078 16.8 9C16.8 4.69218 13.3078 1.2 9 1.2C4.69218 1.2 1.2 4.69218 1.2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z'/%3e %3c/svg%3e");
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form-check .fd-form-check__label {
    color: #F04C2C;
    font-size: 16px;
    min-height: 16px;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0px;
    text-transform: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__form-check .fd-form-check__checkmark::after {
    background-size: 18px;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill='%23F04C2C' d='M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z'/%3e %3c/svg%3e");
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__footer {
    text-align: center;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__button {
    color: #ffffff;
    width: auto;
    border: 1px solid #000000;
    display: inline-block;
    padding: 7px 13px;
    font-size: 16px;
    background: #000000;
    text-align: left;
    font-family: Garamond, serif;
    font-weight: 400;
    line-height: 20px;
    border-radius: 9px;
    letter-spacing: 0.5px;
    text-transform: none;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__button {
      width: 100%;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__success {
    display: none;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901[data-ff-stage="success"] .ff-6609986c71c34c123dd98901__success {
    display: block;
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__success-message {
    color: #333333;
    width: 100%;
    display: block;
    font-size: 16px;
    word-wrap: anywhere;
    min-height: 1.6em;
    text-align: left;
    word-break: break-word;
    font-family: Garamond, serif;
    font-weight: 400;
    line-height: 1.6;
    white-space: normal;
    overflow-wrap: break-word;
    letter-spacing: 0.1px;
    pointer-events: auto;
    text-transform: none;
  }

  @media (max-width: 767px) {
    [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__success-message {
      font-size: 16px;
    }
  }

  [data-ff-el="root"].ff-6609986c71c34c123dd98901 .ff-6609986c71c34c123dd98901__error {
    margin: 15px 0 0 0;
  }
</style>

<div class="ff-6609986c71c34c123dd98901" data-ff-el="root" data-ff-version="3" data-ff-type="inline" data-ff-name="inlineNoImage" data-ff-stage="default">
  <!--tpl {% block config %} tpl-->
  <div data-ff-el="config" data-ff-config="eyJ0cmlnZ2VyIjp7Im1vZGUiOiJpbW1lZGlhdGVseSIsInZhbHVlIjowfSwib25TdWNjZXNzIjp7Im1vZGUiOiJtZXNzYWdlIiwibWVzc2FnZSI6IiIsInJlZGlyZWN0VXJsIjoiaHR0cHM6Ly9idXJlYXUtaW5jLmNvbS9pbmZvIn0sImNvaSI6ZmFsc2UsInNob3dGb3JSZXR1cm5WaXNpdG9ycyI6dHJ1ZSwibm90aWZpY2F0aW9uIjpmYWxzZX0=" style="display: none"></div>
  <!--tpl {% endblock %} tpl-->
  <div class="ff-6609986c71c34c123dd98901__container">
    <div class="ff-6609986c71c34c123dd98901__wrapper">
      <form class="ff-6609986c71c34c123dd98901__form" action="https://form.flodesk.com/forms/6609986c71c34c123dd98901/submit" method="post" data-ff-el="form">
        <div class="ff-6609986c71c34c123dd98901__title">
          <div></div>
        </div>
        <div class="ff-6609986c71c34c123dd98901__subtitle">
          <div></div>
        </div>
        <div class="ff-6609986c71c34c123dd98901__content fd-form-content" data-ff-el="content">
          <div class="ff-6609986c71c34c123dd98901__fields" data-ff-el="fields">
            <!--tpl {% block fields %} tpl-->

            <div class="ff-6609986c71c34c123dd98901__field fd-form-group">
              <input id="ff-6609986c71c34c123dd98901-firstName" class="ff-6609986c71c34c123dd98901__control fd-form-control" type="text" maxlength="255" name="firstName" placeholder="First name" data-ff-tab="firstName::lastName" />
              <label for="ff-6609986c71c34c123dd98901-firstName" class="ff-6609986c71c34c123dd98901__label fd-form-label">First name</label>
            </div>


            <div class="ff-6609986c71c34c123dd98901__field fd-form-group">
              <input id="ff-6609986c71c34c123dd98901-lastName" class="ff-6609986c71c34c123dd98901__control fd-form-control" type="text" maxlength="255" name="lastName" placeholder="Last name" data-ff-tab="lastName:firstName:email" />
              <label for="ff-6609986c71c34c123dd98901-lastName" class="ff-6609986c71c34c123dd98901__label fd-form-label">Last name</label>
            </div>


            <div class="ff-6609986c71c34c123dd98901__field fd-form-group">
              <input id="ff-6609986c71c34c123dd98901-email" class="ff-6609986c71c34c123dd98901__control fd-form-control" type="text" maxlength="255" name="email" placeholder="Email address" data-ff-tab="email:lastName:submit" required />
              <label for="ff-6609986c71c34c123dd98901-email" class="ff-6609986c71c34c123dd98901__label fd-form-label">Email address</label>
            </div>

            <input type="text" maxlength="255" name="confirm_email_address" style="display: none" />
            <!--tpl {% endblock %} tpl-->
          </div>

          <div class="ff-6609986c71c34c123dd98901__footer" data-ff-el="footer">
            <button type="submit" class="ff-6609986c71c34c123dd98901__button fd-btn" data-ff-el="submit" data-ff-tab="submit">
              <span>Subscribe</span>
            </button>
          </div>
        </div>
        <div class="ff-6609986c71c34c123dd98901__success fd-form-success" data-ff-el="success">
          <div class="ff-6609986c71c34c123dd98901__success-message">
            <div>
              <div>
                <div data-paragraph="true">Thank you!</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ff-6609986c71c34c123dd98901__error fd-form-error" data-ff-el="error"></div>
      </form>
    </div>
  </div>
</div>
<script>
  (function(w, d, t, h, s, n) {
    w.FlodeskObject = n;
    var fn = function() {
      (w[n].q = w[n].q || []).push(arguments);
    };
    w[n] = w[n] || fn;
    var f = d.getElementsByTagName(t)[0];
    var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
    var sm = d.createElement(t);
    sm.async = true;
    sm.type = 'module';
    sm.src = h + s + '.mjs' + v;
    f.parentNode.insertBefore(sm, f);
    var sn = d.createElement(t);
    sn.async = true;
    sn.noModule = true;
    sn.src = h + s + '.js' + v;
    f.parentNode.insertBefore(sn, f);
  })(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd');
</script>
<script>
  window.fd('form:handle', {
    formId: '6609986c71c34c123dd98901',
    rootEl: '.ff-6609986c71c34c123dd98901',
  });
</script>`

export default FloDesk