import React from 'react'
import Layout from "../components/layout"
import FloDesk from "../components/floDesk"
import {graphql} from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import SEO from "../components/seo"
import './info.css'

export default function Info(props) {
    const information = props.data.contentfulInfo.galleryInformation
    const inforRichText = renderRichText(information)

    return (
        <Layout>
          <SEO 
            title={`Gallery Information`}
            description={`Gallery hours, staff, and directions`}
          />
           <h1 className="visibility-hidden">Gallery Information</h1>
            <div className='info-cont'>
                {inforRichText}
                <div className="mailing-list-cont" dangerouslySetInnerHTML={{ __html: FloDesk}} />
            </div> 
        </Layout>
    )
}

export const pageQuery = graphql
`
query galleryInfo{
    contentfulInfo {
      galleryInformation {
        raw
      }
    }
  }
`